<template>
  <div>
    <b-card
      title="Delete/edit chat patterns"
    >

      <!--      <b-card-text>Delete/Edit chat patterns.</b-card-text>-->

      <!-- toggle button -->
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        v-b-toggle.collapse-1
        variant="outline-primary"
      >
        Edit existing chat patterns
      </b-button>

      <b-collapse
        id="collapse-1"
        class="mt-2"
      >
        <div>
          <div>
            <b-form
              ref="form"
              :style="{height: trHeight}"
              class="repeater-form"
              @submit.prevent="repeateAgain"
            >

              <!-- Row Loop -->
              <b-row
                v-for="(item, index) in items"
                :id="item.id"
                :key="item.id"
                ref="row"
                :name="item.name"
              >
                <!-- Profession -->
                <b-col
                  lg="2"
                  md="1"
                >
                  <b-form-group
                    label="Name"
                    label-for="Name"
                  >
                    <!--                    {{ value }}-->
                    <!--                    {{ item.id }}-->
                    {{ item.name }}
                  </b-form-group>
                </b-col>

                <!-- Remove Button -->
                <b-col
                  lg="2"
                  md="3"
                  class="mb-50"
                >
                  <b-button
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    v-b-toggle.collapse-1
                    variant="outline-warning"
                    class="mt-0 mt-md-2"
                    @click="editItem(index)"
                  >
                    <feather-icon
                      icon="EditIcon"
                      class="mr-25"
                    />
                    <span>Edit</span>
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    variant="outline-danger"
                    class="mt-0 mt-md-2 ml-2"
                    @click="removeItem(index)"
                  >
                    <feather-icon
                      icon="XIcon"
                      class="mr-25"
                    />
                    <span>Delete</span>
                  </b-button>
                </b-col>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>

            </b-form>
          </div>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="repeateAgain"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-25"
            />
            <span>Add New</span>
          </b-button>
        </div>
      </b-collapse>
    </b-card>
    <b-card-code title="Categorys">

      <!-- custom checkbox -->
      <b-form-group label="Choose categorys for messages:">
        <b-form-checkbox-group
          id="checkbox-group-1"
          v-model="selected"
          :options="options"
          name="flavour-1"
          class="demo-inline-spacing"
        />
      </b-form-group>
      <b-card-text class="mb-0">
        Selected: <strong>{{ selected }}</strong>
      </b-card-text>

    </b-card-code>
    <b-card-code
      title="Load Messages"
    >
      <!--      <b-card-text>-->
      <!--        <span>Drop/Choose .txt File with new Messages on each Line</span>-->
      <!--      </b-card-text>-->
      <!--      <div>-->
      <!--        &lt;!&ndash; Styled &ndash;&gt;-->
      <!--        <b-form-file-->
      <!--          v-model="file"-->
      <!--          placeholder="Choose a file or drop it here..."-->
      <!--          drop-placeholder="Drop file here..."-->
      <!--          @change="selectedFile"-->
      <!--        />-->

      <!--        <b-card-text class="my-1">-->
      <!--          Selected file: <strong>{{ file ? file.name : '' }}</strong>-->
      <!--        </b-card-text>-->
      <!--      </div>-->
      <div
        v-cloak
        id="app"
      >
        <input
          ref="myFile"
          type="file"
          @change="selectedFile"
        ><br>

        <label for="textarea-auto-height" />
        <b-form-textarea
          id="textarea-auto-height"
          v-model.lazy="filetext"
          debounce="3000"
          placeholder="Auto height textarea"
          rows="3"
          max-rows="20"
        />
      </div>
      <div class="mt-3">
        <h3>
          Or create new Messages
        </h3>
      </div>
      <b-card-text>
        <span>Messages are added by clicking pressing the</span>
        <code>Enter</code>
        <span>Key</span>
      </b-card-text>
      <div>
        <label for="tags-basic">Type a new Message and press enter</label>
        <b-form-tags
          v-model="value"
          placeholder="Enter messages"
          input-id="tags-basic"
          class="mb-2"
        />
        <li
          v-for="item in testCollection"
          :key="item.key"
        >
          {{ item }}
        </li>
        <b-card-text>Value: {{ selected }}</b-card-text>
        <!--        <b-card-text>Value: {{ name }}</b-card-text>-->
      </div>
    </b-card-code>
    <b-card-code title="">
      <b-row>
        <b-col
          md="6"
          xl="4"
          class="mb-1"
        >
          <!-- basic -->
          <b-form-group
            label="Chatpattern Name"
            label-for="basicInput"
          >
            <b-form-input
              id="Chatpattern-name"
              v-model.lazy="name"
              placeholder="Enter Name"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <div class="demo-inline-spacing">
            <b-button
              id="txtName"
              v-model="name"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              block
              variant="primary"
              type="text"
              @click="insertRecord"
            >
              Save
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card-code>
  </div>
</template>
<script>

import BCardCode from '@core/components/b-card-code'
import {
  BFormTags,
  BCardText,
  // BFormFile,
  BButton,
  BFormCheckboxGroup,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  VBTooltip,
  BCard,
  BCollapse,
  VBToggle,
  BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { codeBasic, codemultipleChoice } from './code'

// import db from '../db'
export default {
  components: {
    BCardCode,
    BCol,
    BRow,
    BFormInput,
    BFormTags,
    BButton,
    BCardText,
    // BFormFile,
    BFormGroup,
    BCard,
    BCollapse,
    BFormTextarea,
    BFormCheckboxGroup,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      testCollection: [],
      name: null,
      filetext: null,
      file: null,
      file2: null,
      general: false,
      nextTodoId: 1,
      value: ['Hi', 'How are you?', 'Great'],
      selected: [],
      options: [
        { text: 'General', value: 'General' },
        { text: 'Mario Kart', value: 'MarioKart' },
        { text: 'Just Chatting', value: 'JustChatting' },
        { text: 'League of Legends', value: 'League' },
        { text: 'Valorant', value: 'Valorant' },
      ],
      items: [{
        id: 1,
        name: 'male',
        selected1: 'designer',
        prevHeight: 0,
      }],
      codeBasic,
      codemultipleChoice,
    }
  },
  watch: {
    value(val) {
      this.filetext = val.join('\n')
      console.log('Val')
      console.log(val)
    },
    selected(val) {
      if (val.includes('General') && !this.general) {
        this.selected = ['General', 'MarioKart', 'JustChatting', 'League', 'Valorant']
        this.general = true
      }
      if (this.general && !val.includes('General')) {
        console.log('MHM')
        this.general = false
      }
      if (val.includes('General') && this.general && (!val.includes('MarioKart') || !val.includes('JustChatting') || !val.includes('League'))) {
        this.selected.splice(this.selected.indexOf('General'), 1)
      }
    },
    filetext(val) {
      this.value = val.split('\n')
    },
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  mounted() {
    // Get Existing Chat pattern
    const sx = this.$firebase.firestore()
    sx.collection('User')
      .doc('Public')
      .collection('Chatlists')
      .doc('General')
      // .collection('All')
      // .doc('Allgemein')
      // .get('3')
      .get()
      .then(snap => {
        // console.log(snap.data())
        console.log(snap.id)
        console.log(snap.data())
        const test = snap.data()
        console.log(test[4])
        console.log('MÖÖÖP')
        console.log(test.WASGEHTT)
        // eslint-disable-next-line no-restricted-syntax,array-callback-return
        Object.keys(test).map(key => {
          console.log(`KEY${key}`)
          this.items.push({
            id: this.nextTodoId += 1,
            name: key,
          })
          // console.log('FOLLLOWWWINGG')
          // this.selected = test[key].Category
          // this.value = test[key].text
          // console.log(test[key].Category)
          // // eslint-disable-next-line dot-notation
          // console.log(`HALLODING${test[key]['text'][1]}`)
          // eslint-disable-next-line no-plusplus,vars-on-top
          for (let i = 0; i < test[key].length; i++) {
            console.log(test[key][i])
          }
          // eslint-disable-next-line array-callback-return
          // Object.keys(test[key]).map(X => {
          //   console.log('TESST')
          //   console.log(X)
          //   console.log(key[X])
          // })
        })
        // const test = { [doc.id]: doc.data() }
        // console.log(test['Allgemein'][3])
      })
  },
  methods: {
    selectedFile() {
      console.log('selected a file')
      console.log(this.$refs.myFile.files[0])

      const file = this.$refs.myFile.files[0]
      if (!file || file.type !== 'text/plain') return

      // Credit: https://stackoverflow.com/a/754398/52160
      const reader = new FileReader()
      reader.readAsText(file, 'UTF-8')
      console.log('TESST')
      console.log(reader)
      reader.onload = evt => {
        this.filetext = evt.target.result
        this.value = evt.target.result.split('\n')
        // eslint-disable-next-line no-global-assign,no-restricted-globals
        // this.name = evt.target.result
        console.log('OUTPUTTTT')
        console.log(evt.target.result)
      }
      reader.onerror = evt => {
        console.log('ERROR')
        console.error(evt)
      }
    },
    updateRecord() {
      const db = this.$firebase.firestore()
      db.collection('User')
        .doc('M2SjHXgnSJXaRI2UgmwN')
        .get()
        .then(snapshot => {
          const document = snapshot.data()
          console.log(document.data())
          return document
          // do something with document
        })
        .catch(error => {
          console.log(error)
        })
    },
    editItem(index) {
      const sx = this.$firebase.firestore()
      sx.collection('User')
        .doc('Public')
        .collection('Chatlists')
        .doc('General')
        .get()
        .then(snap => {
          // console.log(snap.data())
          const test = snap.data()
          console.log(test[4])
          // eslint-disable-next-line no-restricted-syntax,array-callback-return
          // console.log('INDEEXXXXX')
          // console.log(index)
          // console.log(this.items[index].name)
          // console.log(test.[this.items[index].name])
          // console.log('ITEMWEG')
          this.selected = test.[this.items[index].name].Category
          this.value = test.[this.items[index].name].text
          this.name = this.items[index].name
        })
      // const test = { [doc.id]: doc.data() }
      // console.log(test['Allgemein'][3])
    },
    repeateAgain() {
      this.items.push({
        id: this.nextTodoId += this.nextTodoId,
      })

      // this.$nextTick(() => {
      //   this.trAddHeight(this.$refs.row[0].offsetHeight)
      // })
    },
    removeItem(index) {
      this.items.splice(index, 1)
      // this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    // initTrHeight() {
    //   this.trSetHeight(null)
    //   this.$nextTick(() => {
    //     this.trSetHeight(this.$refs.form.scrollHeight)
    //   })
    // },
    // INSERT DOCUMENT
    // insertRecord() {
    //   const db = this.$firebase.firestore()
    //   const citiesRef = db.collection('User')
    //   citiesRef.doc('Public').collection('Chatlists').add({
    //     title: 'WAR',
    //   })
    //     .then(docRef => {
    //       console.log('Doc Created', docRef.id)
    //     })
    //     .catch(error => {
    //       console.error('Error adding document: ', error)
    //     })
    // },
    // INSERTCOLLECTION
    insertRecord() {
      const db = this.$firebase.firestore()
      const citiesRef = db.collection('User')
      let i
      // eslint-disable-next-line no-plusplus
      for (i = 0; i < this.selected.length; i++) {
        console.log(this.selected[i])
        citiesRef.doc('Public').collection('Chatlists').doc(this.selected[i]).update({
          [this.name]: {
            text: this.value,
            Category: this.selected,
          },
        })
          .then(() => {
            console.log('Collection Created')
          })
          .catch(error => {
            console.error('Error adding document: ', error)
          })
      }
    },
  },
}
</script>
